<template>
    <div class="memberPage">
        <!-- <div class="tree">
            <stationSelect @checkedStation='checkedStation' :stationIds='stationArray' :areaList='areaList'></stationSelect>
        </div> -->
        <div class="roleList">
            <span :class="isOpenRreaList === true ? 'roleisClose':'roleisOpen'" @click="isOpenRrea">
                <Icon type="ios-arrow-forward" />
            </span>
            <Checkbox :indeterminate="indeterminateArea" :value="checkAllArea" @click.prevent.native="handleCheckAllArea">全选</Checkbox>
            <div style="flex: 1">
            <CheckboxGroup v-if="isOpenRreaList == true" v-model="formData.areaCode" @on-change="checkAllGroupChangeArea">
                <Checkbox v-for="(item, index) in areaList" :key="index" :label="item.id">{{item.name}}</Checkbox>
            </CheckboxGroup>
            </div>
        </div>
        <div class="table-div">
            <div class="roleList">
                <span :class="isOpen === true ? 'roleisClose':'roleisOpen'" @click="isOpenRole">
                    <Icon type="ios-arrow-forward" />
                </span>
                <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
                <div style="flex: 1">
                <CheckboxGroup v-if="isOpen == true" v-model="formData.roleId" @on-change="checkAllGroupChange">
                    <Checkbox v-for="(item, index) in roleList" :key="index" :label="item.id">{{item.name}}</Checkbox>
                </CheckboxGroup>
                </div>
            </div>
            <!-- <div style="height: calc(100% - 70px)"> -->
                <!-- <GeminiScrollbar style="height:200px;overflow: auto;"> -->
                <Table class="table-list" height="400" :loading="loading" :columns="columns" :data="data" @on-selection-change='checkedMember' no-data-text="没有符合查询条件的数据">
                    <template slot-scope="{ row }" slot="roleId">
                        {{ getRoleName(row.roleId) }}
                    </template>
                    <template slot-scope="{ row }" slot="areaCode">
                        {{ getAreaName(row.areaCode) }}
                    </template>
                </Table>
                <!-- </GeminiScrollbar> -->
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import request from '@/utils/request'
import stationSelect from './member_left_tree.vue'
const columns = [
    {
        type: 'selection',
        width: 60,
        align: 'center',
    },
    {
        title: '姓名',
        key: 'name',
    },
    {
        title: '手机号',
        key: 'mobileNum',
    },
    {
        title: '电话号码',
        key: 'telNum',
    },
    {
        title: '邮箱',
        key: 'email',
    },
    {
        title: '传真号码',
        key: 'faxNum',
    },
    {
        title: '角色名称',
        slot: 'roleId',
    },
    {
        title: '所属区域',
        slot: 'areaCode',
    },
    {
        title: '职务',
        key: 'duty',
    },
    {
        title: '住址',
        key: 'address',
    },
]
export default Vue.extend({
    props: {
        checkedMemberData: {
            type: Array,
        },
        areaList: {
            type: Array,
        },
        roleList: {
            type: Array,
        },
    },
    watch: {
        checkedMemberData() {
            this.setRoleValue()
            this.setAreaValue()
            this.getData()
        },
    },
    components: {
        stationSelect,
    },
    data() {
        return {
            loading: false,
            columns,
            data: [],
            formData: {
                areaCode: [],
            },
            indeterminate: false,
            checkAll: true,
            stationArray: [],
            isOpen: true,
            indeterminateArea: false,
            checkAllArea: true,
            isOpenRreaList: true,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            Promise.all([
                this.setRoleValue(),
                this.setAreaValue(),
            ]).then((e) => {
                if (!this.checkedMemberData || this.checkedMemberData.length === 0) {
                    this.getData()
                } else {
                    this.setRoleValue()
                    this.setAreaValue()
                    this.getData()
                }
            })
        },
        checkedStation(value) {
            const stationIds = value.map((e) => e.id)
            const stationNames = value.map((e) => e.name)
            this.formData.areaCode = stationIds
            this.getData()
        },
        setRoleValue() {
            if (this.checkedMemberData && this.checkedMemberData.length > 0) {
                let roleArray = this.checkedMemberData.map((memberData) => memberData.roleId)
                roleArray = [...new Set(roleArray)]
                this.formData.roleId = roleArray
                if (this.roleList.length !== roleArray.length) {
                    this.checkAll = false
                    this.indeterminate = true
                }
            } else {
                this.formData.roleId = this.roleList.map((item) => item.id);
            }
        },
        getRoleName(value) {
            const current =  this.roleList.filter((e) => {
                if (e.id === value) {
                    return e
                }
            })
            return current[0].name
        },
        setAreaValue() {
            if (this.checkedMemberData && this.checkedMemberData.length > 0) {
                let areaArray = this.checkedMemberData.map((memberData) => memberData.areaCode)
                areaArray = [...new Set(areaArray)]
                this.formData.areaCode = areaArray
            } else {
                this.formData.areaCode = this.areaList.map((item) => item.id);
            }
            this.stationArray = this.formData.areaCode
        },
        getAreaName(value) {
            const current =  this.areaList.filter((e) => {
                if (e.id === value) {
                    return e
                }
            })
            return current[0].name
        },
        getData() {
            this.loading = true
            const {roleId, areaCode} = this.formData
            const params = {roleId: roleId && roleId.length > 0 ? roleId.join(',') : '',
             areaCode: areaCode && areaCode.length > 0 ? areaCode.join(',') : ''}
            request.get('/api/respond/respond_person/list', params)
            .then((data) => {
                data.map((e) => e._checked = false)
                // console.log(data)
                this.data = data
                this.setCheckMemberValue()
                this.loading = false
            })
        },
        setCheckMemberValue() {
            if (this.checkedMemberData && this.checkedMemberData.length > 0) {
                this.checkedMemberData.forEach((member) => {
                    this.data.forEach((element) => {
                        if (element.id === member.id) {
                            element._checked = true
                        }
                    })
                })
            }
        },
        handleCheckAll() {
            if (this.indeterminate) {
                this.checkAll = false;
            } else {
                this.checkAll = !this.checkAll;
            }
            this.indeterminate = false;

            if (this.checkAll) {
                this.formData.roleId = this.roleList.map((e) => e.id);
            } else {
                this.formData.roleId = [];
            }
            this.getData()
        },
        checkAllGroupChange(data) {
            if (data.length === this.roleList.length) {
                this.indeterminate = false;
                this.checkAll = true;
            } else if (data.length > 0) {
                this.indeterminate = true;
                this.checkAll = false;
            } else {
                this.indeterminate = false;
                this.checkAll = false;
            }
            this.getData()
        },
        checkedMember(checkedMembers) {
            this.$emit('checkedMembers', checkedMembers)
        },
        isOpenRole() {
            if (this.isOpen === false) {
                this.isOpen = true
            } else {
                this.isOpen = false
            }
        },
        handleCheckAllArea() {
            if (this.indeterminateArea) {
                this.checkAllArea = false;
            } else {
                this.checkAllArea = !this.checkAllArea;
            }
            this.indeterminateArea = false;

            if (this.checkAllArea) {
                this.formData.areaCode = this.areaList.map((e) => e.id);
            } else {
                this.formData.areaCode = [];
            }
            this.getData()
        },
        checkAllGroupChangeArea(data) {
            if (data.length === this.areaList.length) {
                this.indeterminateArea = false;
                this.checkAllArea = true;
            } else if (data.length > 0) {
                this.indeterminateArea = true;
                this.checkAllArea = false;
            } else {
                this.indeterminateArea = false;
                this.checkAllArea = false;
            }
            this.getData()
        },
        isOpenRrea() {
            if (this.isOpenRreaList === false) {
                this.isOpenRreaList = true
            } else {
                this.isOpenRreaList = false
            }
        },
    },
})
</script>
<style scoped>
    .memberPage{
        width: 92%;
    }
    .roleList{
        display: flex;
    }
    .roleisOpen{
        margin: 0 2px 0 0px;
        cursor: pointer;
        transition: all .2s ease-in-out;
    }
    .roleisClose{
        transform: rotate(90deg);
        margin: 0 2px 0 0px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        height: 33px;
    }
.ivu-checkbox-wrapper {
font-size: 16px;
color: #0077EE;
}

.ivu-table-wrapper {
    position: relative;
    border: none;
    border-right: 0;
    overflow: hidden;
}
::v-deep .ivu-table{
    background: transparent;
    color: #fff;
    font-size: 16px;
}
::v-deep .ivu-table:before{
    background-color: transparent;
}
::v-deep .ivu-table th{
    background: #fff;
    border-color: #00A0E9;
}
::v-deep .ivu-table-header{
    border-radius: 8px;
}
::v-deep .ivu-table-header thead tr th {
    background: #00A0E9;
    border-color: #00A0E9;
    color: #fff;
}
::v-deep .ivu-table td {
    background: transparent;
    border-color: #00A0E9;
}
::v-deep .ivu-table-cell {
    padding-right: 0;
    padding-left: 13px;
    display: block;
    text-align: center;
}
/* 滚动 + 加载中 */
::v-deep .ivu-table-overflowY::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #0077EE;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 1px solid #0077EE;
}
::v-deep .ivu-spin-fix{
    background-color: transparent;
}
::v-deep .ivu-table-wrapper>.ivu-spin-fix{
    border: none;
}
</style>